<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.agendamento.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.agendamento.titulos.novo')"
      :sem-botao-ok="!buscarPermissao('Agendamento', 'Inserir')"
      sem-filtro
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    />
    <botao-padrao
      v-if="podeExcluir && buscarPermissao('Agendamento', 'Deletar')"
      class="my-2"
      outlined
      color="danger"
    >
      <v-icon>$mdiTrashCanOutline</v-icon>
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>
    <tabela-padrao-prime-vue
      ref="tabela"
      v-model="tabela.selecionados"
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      :mostrar-acoes="true"
      filtros-com-colunas
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :mostrar-seletor="buscarPermissao('Agendamento', 'Deletar')"
      :pagina="tabela.paginaAtual"
      @paginar="listarRegistros"
      @filtrarGlobal="listarRegistros"
      @filtroGlobal="(v) => (filtroAplicado.filter = v)"
      @nova-ordenacao="ordenacaoColuna"
    >
      <template v-slot:acoes="{ slotProps }">
        <div
          class="d-flex"
          style="gap: 2px"
        >
          <div class="d-flex">
            <dropdown-padrao
              text
              color="secondary"
            >
              <template #botao>
                <v-icon> $dotsVertical </v-icon>
              </template>
              <div>
                <dropdown-padrao-item
                  v-if="buscarPermissao('Agendamento', 'Visualizar')"
                  text
                  color="secondary"
                  @click="abrirVisualizar(slotProps.data)"
                >
                  {{ $t('geral.botoes.visualizar') }}
                </dropdown-padrao-item>
                <dropdown-padrao-item
                  v-if="buscarPermissao('Agendamento', 'Editar')"
                  text
                  color="secondary"
                  :disabled="
                    slotProps.data.situacao !== 'AguardandoAnaliseTecnica'
                  "
                  @click="abrirEditar(slotProps.data)"
                >
                  {{ $t('geral.botoes.editar') }}
                </dropdown-padrao-item>
                <dropdown-padrao-item
                  v-if="buscarPermissao('Agendamento', 'Programar')"
                  text
                  color="secondary"
                  :disabled="!podeProgramar(slotProps.data)"
                  @click="abrirProgramar(slotProps.data)"
                >
                  {{ $t('geral.botoes.programar') }}
                </dropdown-padrao-item>
                <dropdown-padrao-item
                  v-if="buscarPermissao('Agendamento', 'Executar')"
                  text
                  color="secondary"
                  :disabled="!podeExecutar(slotProps.data)"
                  @click="abrirExecutar(slotProps.data)"
                >
                  {{ $t('geral.botoes.executar') }}
                </dropdown-padrao-item>
                <dropdown-padrao-item
                  v-if="buscarPermissao('Agendamento', 'AnaliseCritica')"
                  text
                  color="secondary"
                  :disabled="!podeFazerAnaliseCritica(slotProps.data)"
                  @click="abrirAnaliseCritica(slotProps.data)"
                >
                  {{ $t('modulos.agendamento.botoes.analise_critica') }}
                </dropdown-padrao-item>
                <dropdown-padrao-item
                  v-if="buscarPermissao('Agendamento', 'Confirmar')"
                  text
                  color="secondary"
                  :disabled="!podeConfirmar(slotProps.data)"
                  @click="abrirConfirmar(slotProps.data)"
                >
                  {{ $t('geral.botoes.confirmar') }}
                </dropdown-padrao-item>
                <dropdown-padrao-item
                  v-if="buscarPermissao('Agendamento', 'RegistrarEvento')"
                  text
                  color="secondary"
                  :disabled="!podeRegistrarEvento(slotProps.data)"
                  @click="abrirRegistroEventos(slotProps.data)"
                >
                  {{ $t('modulos.agendamento.botoes.registar_eventos') }}
                </dropdown-padrao-item>
                <dropdown-padrao-item
                  v-if="buscarPermissao('Agendamento', 'Executar')"
                  text
                  color="secondary"
                  :disabled="!slotProps.data.flagPodeIniciarViagem"
                  @click="abrirIniciarViagem(slotProps.data)"
                >
                  {{ $t('geral.botoes.iniciar_viagem') }}
                </dropdown-padrao-item>
                <dropdown-padrao-item
                  v-if="buscarPermissao('Agendamento', 'Executar')"
                  text
                  color="secondary"
                  :disabled="!slotProps.data.flagPodeFinalizarViagem"
                  @click="abrirFinalizarViagem(slotProps.data)"
                >
                  {{ $t('geral.botoes.finalizar_viagem') }}
                </dropdown-padrao-item>
                <dropdown-padrao-item
                  v-if="buscarPermissao('Agendamento', 'Cancelar')"
                  text
                  color="secondary"
                  :disabled="naoPodeCancelarAgendamento(slotProps.data)"
                  @click="cancelarAgendamento(slotProps.data)"
                >
                  {{ $t('geral.botoes.cancelar') }}
                </dropdown-padrao-item>
              </div>
            </dropdown-padrao>
            <div class="d-flex justify-start">
              <icone-padrao
                class="mr-2"
                :icone="getSituacaoIcon(slotProps.data.situacao)"
                :tooltip="getSituacaoEnum(slotProps.data.situacao)"
              />
            </div>
          </div>
        </div>
      </template>
    </tabela-padrao-prime-vue>
  </div>
</template>

<script>
import CabecalhoPagina from '@/components/layout/CabecalhoPagina.vue';
import helpers from '@/common/utils/helpers';
import AgendamentoService from '@/common/services/cadastros/AgendamentoService.js';
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
import moment from 'moment';
import { mapGetters } from 'vuex';
export default {
  components: {
    CabecalhoPagina,
  },
  data() {
    return {
      EnumAgendamento: [],
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'codigo',
            text: this.$t('modulos.agendamento.tabela.codigo'),
            sortable: true,
          },
          {
            value: 'dataInicial',
            text: this.$t('modulos.agendamento.tabela.data_inical'),
            sortable: true,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, false);
              return '';
            },
          },
          {
            value: 'dataFinal',
            text: this.$t('modulos.agendamento.tabela.data_final'),
            sortable: true,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, false);
              return '';
            },
          },
          {
            value: 'horaInicial',
            text: this.$t('modulos.agendamento.tabela.horario_inicial'),
            sortable: true,
          },
          {
            value: 'horaFinal',
            text: this.$t('modulos.agendamento.tabela.horario_final'),
            sortable: true,
          },
          {
            value: 'tipoVisita.nome',
            text: this.$t('modulos.agendamento.tabela.tipo_visita'),
            sortable: true,
          },
          {
            value: 'solicitante.nome',
            text: this.$t('modulos.agendamento.tabela.solicitante'),
            sortable: true,
          },
          {
            value: 'cidade',
            text: this.$t('modulos.agendamento.tabela.cidade'),
            sortable: true,
          },
          {
            value: 'contratante.nome',
            text: this.$t('modulos.agendamento.tabela.contratante'),
            sortable: true,
          },
          {
            value: 'contatoContratante.nome',
            text: this.$t('modulos.agendamento.tabela.contato_contratante'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      colunaOrdenada: '',
    };
  },
  computed: {
    ...mapGetters('Autenticacao', ['participanteLogadoConta']),
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  async mounted() {
    await this.buscarEnumAgendamento();
    this.listarRegistros();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.agendamento.titulos.listagem')
    );
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'Agendamento', 'Listar');

    const filtros = helpers.buscarStateFiltros(this);
    this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };
    this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    this.tabela.porPagina = filtros?.porPagina ?? 10;
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    buscarEnumAgendamento: async function () {
      this.$store.dispatch(
        'Layout/iniciarCarregamento',
        'Buscando Situações do Agendamento'
      );
      await EnumeradorService.buscar('EnumSituacaoAgendamento')
        .then((res) => {
          this.EnumAgendamento = res;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    filtrar(filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    listarRegistros(
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      helpers.salvarStateFiltros(this, {
        filtroAplicado: this.filtroAplicado,
        paginaAtual,
        porPagina,
      });
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        //sort: this.colunaOrdenada,
        ...this.filtroAplicado,
      };

      this.$store.dispatch('Layout/iniciarCarregamento');
      this.tabela.dados = [];
      AgendamentoService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;
          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    abrirNovo() {
      this.$router.push({ name: 'agendamento-novo' });
    },
    abrirEditar(item) {
      this.$router.push({
        name: 'agendamento-editar',
        params: { id: item.id },
      });
    },
    abrirVisualizar(item) {
      this.$router.push({
        name: 'agendamento-visualizar',
        params: { id: item.id },
      });
    },
    abrirProgramar(item) {
      this.$router.push({
        name: 'agendamento-programar',
        params: { id: item.id },
      });
    },
    abrirConfirmar(item) {
      this.$router.push({
        name: 'agendamento-confirmar',
        params: { id: item.id },
      });
    },
    abrirExecutar(item) {
      const agora = moment();
      const dataInicial = moment(item.dataHoraInicial);
      const dataFinal = moment(item.dataHoraFinal);

      const estaDentroDoPeriodo =
        agora.isAfter(dataInicial) && agora.isBefore(dataFinal);

      if (!estaDentroDoPeriodo) {
        this.confirmar(
          this.$t('geral.titulos.atencao'),
          this.$t(
            'modulos.agendamento.alertas.executar_agendamento_fora_periodo'
          )
        ).then(() => {
          this.$router.push({
            name: 'agendamento-executar',
            params: { id: item.id },
          });
        });
        return;
      }
      this.$router.push({
        name: 'agendamento-executar',
        params: { id: item.id },
      });
    },
    abrirRegistroEventos(item) {
      this.$router.push({
        name: 'agendamento-registro-eventos',
        params: { id: item.id },
      });
    },
    abrirIniciarViagem(item) {
      const dataInicial = new Date(item.dataHoraInicial);
      const dataInicialAjustada = new Date(
        dataInicial.valueOf() + dataInicial.getTimezoneOffset() * 60000
      );
      const dataFinal = new Date(item.dataHoraFinal);
      const dataFinalAjustada = new Date(
        dataFinal.valueOf() + dataFinal.getTimezoneOffset() * 60000
      );
      const agora = new Date();

      if (dataInicialAjustada > agora || dataFinalAjustada < agora) {
        return this.confirmar(
          this.$t('modulos.agendamento.execucao_fora_de_horario'),
          this.$t('modulos.agendamento.execucao_fora_de_horario_texto')
        ).then(() => {
          this.$router.push({
            name: 'agendamento-iniciar-viagem',
            params: { id: item.id },
          });
        });
      }
      this.$router.push({
        name: 'agendamento-iniciar-viagem',
        params: { id: item.id },
      });
    },
    abrirFinalizarViagem(item) {
      this.$router.push({
        name: 'agendamento-finalizar-viagem',
        params: { id: item.id },
      });
    },
    abrirAnaliseCritica(item) {
      this.$router.push({
        name: 'agendamento-analise-critica',
        params: { id: item.id },
      });
    },
    cancelarAgendamento(item) {
      this.confirmar(
        this.$t('geral.titulos.atencao'),
        this.$t('modulos.agendamento.alertas.cancelar_agendamento')
      ).then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        AgendamentoService.cancelar(item.id)
          .then(() => {
            this.toastSucesso(
              this.$t(`modulos.agendamento.cancelamento_sucesso`)
            );
            this.listarRegistros();
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    getSituacaoIcon(situacao) {
      if (situacao) {
        const icon = this.EnumAgendamento.find((x) => x.value === situacao)
          ?.text?.icon;
        return this.$vuetify.icons.values[icon];
      }
    },
    getSituacaoEnum(situacao) {
      if (situacao) {
        const Enum = this.EnumAgendamento.find((x) => x.value === situacao)
          ?.text?.enum;
        return Enum;
      }
    },
    naoPodeCancelarAgendamento(item) {
      const naoPodeCancelar = [
        'EmExecucao',
        'AguardandoAnaliseCritica',
        'Concluido',
        'ReprovadoPeloOrcamento',
        'Cancelado',
      ];
      return naoPodeCancelar.includes(item.situacao);
    },
    podeProgramar(item) {
      if (!this.participanteLogadoConta) {
        return false;
      }
      const podeProgramar = [
        'AguardandoProgramacao',
        'AguardandoConfirmacao',
        'Programado',
      ];
      return podeProgramar.includes(item.situacao);
    },
    podeExecutar(item) {
      const podeExecutar = ['Programado', 'EmExecucao'];
      return (
        podeExecutar.includes(item.situacao) &&
        !item.contratante?.flagParticipanteConta &&
        item.flagPodeExecutarAtendimentoAgendamento
      );
    },

    podeConfirmar(item) {
      const podeConfirmar = ['AguardandoConfirmacao'];
      return podeConfirmar.includes(item.situacao);
    },
    podeRegistrarEvento(item) {
      const podeRegistrarEvento = [
        'EmExecucao',
        'AguardandoAnaliseCritica',
        'Concluido',
      ];
      return (
        podeRegistrarEvento.includes(item.situacao) &&
        !item.contratante?.flagParticipanteConta
      );
    },
    podeFazerAnaliseCritica(item) {
      const podeFazerAnaliseCritica = ['AguardandoAnaliseCritica'];
      return (
        podeFazerAnaliseCritica.includes(item.situacao) &&
        !item.contratante?.flagParticipanteConta &&
        this.participanteLogadoConta
      );
    },
    ordenacaoColuna(ordem) {
      this.colunaOrdenada = ordem;
      this.listarRegistros()
    },
  },
};
</script>

<style scoped></style>
